<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="text-primary " style="font-weight:400" >Clients</h4>
            <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="plus"
                    size="sm" 
                    small
                    @click="addOrEdit(null)"
                    v-if="$gate.hasPermission(['create::customer'])"
                >
                </mdb-btn>
        </div>
        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">
                <div class="tec-search-v-icon">
                    <input   v-model="dataPagination.searchQuery"  style="background:transparent" type="search" ref="searchbar" name="search" placeholder="recherche ..." id="tec-search-v" class="tec-search-v">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        <div class="" v-if="!empty" v-loading="loading">
            <div class="card card-list-os mb-3" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.id">
                <div class="card-body py-1 pl-2 d-flex align-items-center justify-content-between" >
                    <div style="max-width:90%">
                        <div class="mobile-card-header" style="color:grey;font-size:0.8rem">
                            <span class="item-mobile-spa pb-2 d-block" style="font-size:0.7rem">
                                {{ row.code_client }}
                            </span>
                        </div>
                        <h5 class="card-title pb-2 " style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                            <span v-if="row.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                            <span v-if="row.is_active === 0" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                            <span style="text-transform:uppercase;" class="pl-2">{{ row.designation }}</span>
                        </h5>
                        <div  v-if="row.ifu || row.rccm" class="mobile-card-describe mt-n3" style="font-weight:500;text-transform:uppercase;font-size:0.75rem; color:rgba(62, 69, 81, .7);">
                            <span class="item-mobile-spa" style="text-transform:lowercase" >{{ row.ifu}}</span>
                            <span class="item-mobile-spa">{{row.rccm}}</span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="color-normal-reversed fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0">
                <mdb-list-group flush>
                    <mdb-list-group-item  
                    class="bg-primary white-text font-weight-bold" > 
                    <div>MENU</div> 
                    <button class="btn-mob" @click="hideAction()"> 
                    <i class="fas fa-times"></i> 
                    </button> 
                </mdb-list-group-item>
                <mdb-list-group-item  v-if="$gate.hasPermission(['view::customer'])"
                    class="menu-tec-lg"
                    @click.native="details(modalAction.content)"
                    icon="align-center"  
                    style="font-weight:400;">
                    <div class="pl-3 menu-mob-tec">Voir les détails</div>
                </mdb-list-group-item>
                <mdb-list-group-item  v-if="$gate.hasPermission(['edit::customer'])"
                    class="menu-tec-lg" 
                    :disabled="modalAction.content.approuved === 1" 
                    @click.native="addOrEdit(modalAction.content)"
                    icon="edit"  style="font-weight:400;" >
                    <div class="pl-3 menu-mob-tec">Modifier le client</div>
                </mdb-list-group-item>
                <mdb-list-group-item  v-if="$gate.hasPermission(['delete::customer'])"
                    class="menu-tec-lg" 
                    :disabled="modalAction.content.approuved === 1" 
                    :class="(modalAction.content.approuved === 1) ? 'grey-text' :'text-danger'"
                    @click.native="deleteCustomer(modalAction.content.id)"   
                    icon="trash"  style="font-weight:400;" >
                    <div class="pl-3 menu-mob-tec">Supprimer le client</div>
                </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination'
import {
    mdbBtn,
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbBtn,
        mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
        TecPagination
    },
    props:{
        empty:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        dataPagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            modalAction:{
                show:false,
                content:{}
            }
        }
    },
    methods:{
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },
        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        addOrEdit(data = null){
            if(data){
                this.modalAction.show = false
            }
            this.$emit('add_or_edit',data)
        },
        details(row) {
            this.modalAction.show = false
			this.$emit('details_customers',row)
        },

        getIsActiveValue(){
            // 
        },

        loadUsers () {
			this.$emit('load_customers')
        },

        selected(e){
            this.$emit('per_page_selected',e)
        },

        deleteCustomer(id)
        {
            this.modalAction.show = false
            this.$emit('delete_customer', id)
		},

        sendUserIdentifier(id)
        {
            this.modalAction.show = false
            this.$emit('send_customer_identifier', id)
        },
    }
}
</script>